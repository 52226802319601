import { useEffect, useState } from "react";
import {
  useParams,
  useHistory
} from "react-router-dom";
import './App.css';
import { Header } from './components/Header';
import { Chevron } from './components/Chevron';
import { Timer } from './components/Timer';
import { Welcome } from './components/Welcome';
import { Calendar } from './components/Calendar';
import { Schedule } from './components/Schedule/Schedule';
import { Dresscode } from './components/Dresscode';
import { Question } from './components/Question';
import { Accept } from './components/Accept';
import { getData, sendAcceptInvite, sendQuestionInvite } from "./api/api";
import AnimateHeight from "react-animate-height";

const App = () => {
  const history = useHistory()
  const { name } = useParams()

  const [data, setData] = useState(null)
  const [loadingAccept, setLoadingAccept] = useState(false)
  const [loadingQuestion, setLoadingQuestion] = useState(false)
  // const [question, setQuestion] = useState({
  //   food_meat: false,
  //   food_fish: false,
  //   food_veg: false,
  //   alko_vine: false,
  //   alko_champagne: false,
  //   alko_whisky: false,
  //   alko_cognac: false,
  //   alko_aperol: false,
  //   alko_maitai: false,
  //   alko_martini: false,
  //   alko_sunset: false,
  //   alko_whiskey: false,
  //   children: 'no',
  //   message: ''
  // })

  useEffect(() => {
    if (name) fetchData(name)
  }, [name])

  const fetchData = async (name) => {
    const response = await getData(name);
    if (response.status === 'ok') {
      setData(response.data)
    } else {
      history.replace('/')
    }
  }

  const sendAccept = async (accept) => {
    try {
      setLoadingAccept(true)
      const response = await sendAcceptInvite(name, accept);
      if (response.status === 'ok')
        setData({ ...data, accept: "1" })
      else throw response?.message
      setLoadingAccept(false)
    } catch (error) {
      alert(error || 'Произошла ошибка, попробуйте ещё раз')
      console.log(error);
      setLoadingAccept(false)
    }
  }

  const toggleQuestion = (e) => {
    // setQuestion({ ...question, [e.target.name]: e.target.type !== 'checkbox' ? e.target.value : (e.target.checked ? '1' : '0') })
    setData({ ...data, saved_question: false, [e.target.name]: e.target.type !== 'checkbox' ? e.target.value : (e.target.checked ? '1' : '0') })
  }

  const sendQuestion = async () => {
    try {
      setLoadingQuestion(true)
      const response = await sendQuestionInvite(name, data)
      if (response.status === 'ok')
        setData({ ...data, saved_question: true })
      else throw response?.message
      setLoadingQuestion(false)
    } catch (error) {
      alert(error || 'Произошла ошибка, попробуйте ещё раз')
      console.log(error);
      setLoadingQuestion(false)
    }
  }

  // if (name === 'test') {
  //   history.replace('/')
  // }

  // console.log(data);

  return (
    <div className='Container'>
      <Header />
      <Timer />
      <Chevron />
      <Welcome title={data?.welcome_title} text={data?.welcome_text} />
      <Calendar />
      <Schedule />
      {data?.has_accept && <Accept sendAccept={sendAccept} loading={loadingAccept} accept={data?.accept} />}
      <AnimateHeight
        duration={500}
        height={data && data.accept !== '0' ? 'auto' : 0}
      >

        <Dresscode />
        <Question question={data} toggleQuestion={toggleQuestion} sendQuestion={sendQuestion} loading={loadingQuestion} saved={data?.saved_question} />
      </AnimateHeight>
    </div>
  );
}

export default App;
