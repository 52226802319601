import React from 'react'

export const Heart = ({ className }) => {
    return (
        <div className={className}>
            <svg width="40" height="40" viewBox="0 0 252 220" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M126.146 32.8603L113.102 19.8163C87.3281 -5.95829 45.5391 -5.95829 19.7646 19.8163C-6.01007 45.591 -6.01007 87.38 19.7646 113.155L125.831 219.22L125.852 219.199L126.168 219.514L232.234 113.449C258.008 87.674 258.008 45.8853 232.234 20.1108C206.46 -5.66393 164.671 -5.66393 138.896 20.1108L126.146 32.8603Z" fill="#D71920" />
            </svg>


        </div>
    )
}
