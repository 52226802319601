import React from 'react'
import './style.css'
import { Gallery } from '../Gallery'

const img_man = [
    'https://s731591.ha003.t.justns.ru/img/m-1.jpeg',
    'https://s731591.ha003.t.justns.ru/img/m-2.jpeg',
    'https://s731591.ha003.t.justns.ru/img/m-3.jpeg',
    'https://s731591.ha003.t.justns.ru/img/m-4.jpeg',
    'https://s731591.ha003.t.justns.ru/img/m-5.jpeg',
    'https://s731591.ha003.t.justns.ru/img/m-6.jpeg',
    'https://s731591.ha003.t.justns.ru/img/m-7.jpeg',
    'https://s731591.ha003.t.justns.ru/img/m-8.jpeg',
    'https://s731591.ha003.t.justns.ru/img/m-9.jpeg',
    'https://s731591.ha003.t.justns.ru/img/m-10.jpeg',
    'https://s731591.ha003.t.justns.ru/img/mw-1.jpeg',
]

const img_woman = [
    'https://s731591.ha003.t.justns.ru/img/w-1.jpeg',
    'https://s731591.ha003.t.justns.ru/img/w-2.jpeg',
    'https://s731591.ha003.t.justns.ru/img/w-3.jpeg',
    'https://s731591.ha003.t.justns.ru/img/w-4.jpeg',
    'https://s731591.ha003.t.justns.ru/img/w-5.jpeg',
    'https://s731591.ha003.t.justns.ru/img/w-6.jpeg',
    'https://s731591.ha003.t.justns.ru/img/w-7.jpeg',
    'https://s731591.ha003.t.justns.ru/img/w-8.jpeg',
    'https://s731591.ha003.t.justns.ru/img/mw-1.jpeg',
]

export const Dresscode = () => {
    return (
        <div className='Dresscode'>
            <div className='Title'>
                Дресс-код
            </div>
            <div className='Dresscode__description'>Мы очень старались сделать праздник красивым и будем рады, если в своих нарядах вы поддержите цветовую гамму нашей свадьбы</div>
            <div className='Dresscode__container'>
                <div className='Dresscode__item' style={{ background: '#000' }} />
                <div className='Dresscode__item' style={{ background: '#faebd7' }} />
                <div className='Dresscode__item' style={{ background: '#B5B8B1' }} />
                <div className='Dresscode__item' style={{ background: '#C4C482' }} />
                <div className='Dresscode__item' style={{ background: '#FFE7B2' }} />
            </div>
            <div className='Dresscode__subtitle'>Девушки</div>
            <div className='Dresscode__text'>Вечерние платья, костюмы, юбки, блузки. Приветствуются аксессуары в палитре торжества.</div>
            <div className='Dresscode__text'>Будем признательны, если образ «total white» будет только у невесты.</div>
            <Gallery data={img_woman} />
            <div className='Dresscode__subtitle'>Мужчины</div>
            <div className='Dresscode__text'>Рубашка или футболка, брюки и пиджак.</div>
            <Gallery data={img_man} />
        </div>
    )
}