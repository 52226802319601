import React from 'react'
import './style.css'

export const Schedule = () => {
    return (
        <div className='Schedule'>
            <div className='Title'>
                Свадебное расписание
            </div>
            <div className='Schedule__item'>
                <div className='Schedule__item_time'>
                    11:00<br />
                    27.08.2024
                </div>
                <div>
                    Дворец бракосочетания №1 <br />
                    <a>Санкт-Петербург Английская наб., 28</a>
                </div>
            </div>
            <div className='Schedule__item'>
                <div className='Schedule__item_time'>
                    11:00<br />
                    27.08.2024
                </div>
                <div>
                    Дворец бракосочетания №1 <br />
                    <a>Санкт-Петербург Английская наб., 28</a>
                </div>
            </div>
            <div className='Schedule__item'>
                <div className='Schedule__item_time'>
                    11:00<br />
                    27.08.2024
                </div>
                <div>
                    Дворец бракосочетания №1 <br />
                    <a>Санкт-Петербург Английская наб., 28</a>
                </div>
            </div>
        </div>
    )
}
