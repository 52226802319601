import React from 'react'
import './style.css'

export const Chevron = () => {
    return (
        <div className='Chevron'>
            <div className='Chevron__item' />
        </div>
    )
}
