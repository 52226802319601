import React from 'react'
import './style.css'

export const Header = () => {
    return (
        <div className='Header'>

            <div className='Header__background' />
            <div>
                <div className='Header__textblock_first'>ОНА СКАЗАЛА</div>
            </div>
            <div className='Header__textblock_second'>
                Александр & Алиса <br />
                Приглашают на свадьбу <br />
                <div className='Header__textblock_third'>
                    В Санкт—Петербурге
                </div>
            </div>
        </div>
    )
}
