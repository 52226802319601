import React, { useEffect, useRef } from 'react'
import './style.css'

export const Gallery = ({ data }) => {
    const ref = useRef(null)

    useEffect(() => {
        if (ref && ref.current) {
            let slider = ref.current
            let isDown = false;
            let startX;
            let sLeft;
            slider.scrollLeft = 0;
            slider.addEventListener('mousedown', (e) => {
                isDown = true;
                startX = e.pageX;
                sLeft = slider.scrollLeft;
            });

            slider.addEventListener('mouseleave', () => {
                isDown = false;
            });
            slider.addEventListener('mouseup', () => {
                isDown = false;
            });

            slider.addEventListener('mousemove', (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX;
                const dragged = x - startX;
                slider.scrollLeft = sLeft - dragged;
            });

            // slider.addEventListener('wheel', (e) => {
            //     if (!isDown) return;
            //     e.preventDefault();
            //     slider.scrollLeft -= (e.wheelDelta);
            // });

            return () => {
                slider.removeEventListener('mouseleave', () => {
                    isDown = false;
                });
                slider.removeEventListener('mouseup', () => {
                    isDown = false;
                });

                slider.removeEventListener('mousemove', (e) => {
                    if (!isDown) return;
                    e.preventDefault();
                    const x = e.pageX;
                    const dragged = x - startX;
                    slider.scrollLeft = sLeft - dragged;
                });
            }
        }
    }, []);


    return (
        <div className="Gallery" ref={ref}>
            {data && data.map(item => {
                return <div className="Gallery__slide" key={item} /* style={{ backgroundImage: `url(${item})` }} */ >
                    <img src={item} alt="" />
                </div>
            })}
        </div>
    )
}
