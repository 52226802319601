import React from 'react'
import './style.css'
import { Loader } from '../Loader'

export const Button = ({ children, onClick, loading, mode }) => {
    return (
        <div className='Button' onClick={onClick}>
            <div className={'Button__in' + (mode === 'success' ? ' Button__in_success' : '')} >{loading ? <Loader /> : children}</div>
        </div>
    )
}
