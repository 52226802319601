import React from 'react'
import './style.css'

export const Welcome = ({ title, text }) => {
    return (
        <div className='Welcome'>
            <div className='Title'>
                {title}
            </div>
            <div className='Welcome__text'>
                {text}
            </div>
            <div className='Welcome__subtitle'>
                Мы станем семьёй
            </div>
        </div>
    )
}
